import type { RootNode } from '@strapi/blocks-react-renderer/dist/BlocksRenderer';
import type { TextInlineNode } from '@strapi/blocks-react-renderer/dist/Text';

// We need to manually type LinkInlineNode since it's not exposed by @strapi/blocks-react-renderer

interface LinkInlineNode {
  type: 'link';
  url: string;
  children: TextInlineNode[];
}

type InlineNode = TextInlineNode | LinkInlineNode;

const isInlineNode = (node: unknown): node is InlineNode =>
  typeof node === 'object' &&
  node !== null &&
  'type' in node &&
  (node.type === 'text' || node.type === 'link');

// convertBlocksToText focuses on rich text nodes of type paragraph, list, text, and link.
// The extracted text is used to generate structured data snippets, such as FAQJsonLd

export const convertBlocksToText = (blocks: RootNode[]): string => {
  const extractText = (node: InlineNode): string =>
    node.type === 'text'
      ? node.text || ''
      : node.children.map(extractText).join(' ');

  const extractTextFromChildren = (children: unknown[]): string =>
    children.filter(isInlineNode).map(extractText).join(' ');

  return blocks
    .flatMap((block) => {
      if (block.type === 'paragraph') {
        return extractTextFromChildren(block.children);
      }

      if (block.type === 'list') {
        return block.children
          .map((listItem) => extractTextFromChildren(listItem.children))
          .join(' ');
      }
      return '';
    })
    .filter(Boolean)
    .join(' ');
};
